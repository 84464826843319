<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <v-row class="flex justify-between flex-wrap mt-2 ml-1">
            <v-dialog v-model="dialog" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="addRole"
                  color="info"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  Add Role
                </v-btn>
              </template>
              <v-card>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-title>
                    <span class="headline" v-if="isEditing">Edit Role</span>
                    <span v-else class="text-h5">Add Role</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-text-field
                          label="Role Name*"
                          v-model="roleName"
                          :rules="[
                            () =>
                              !!roleName || 'This field role name is requied',
                          ]"
                          :error-messages="errorMessages"
                          required
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-select
                          v-model="permission"
                          :items="permissions"
                          :rules="[
                            () =>
                              !!permission ||
                              'This field permission is required',
                          ]"
                          item-text="name"
                          item-value="code"
                          label="Select Permissions"
                          multiple
                          chips
                          hint="Select the Permission"
                          persistent-hint
                        ></v-select>
                      </v-row>
                    </v-container>
                    <small>*indicates required field</small>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                      Close
                    </v-btn>
                    <v-btn
                      v-if="!isEditing"
                      color="blue darken-1"
                      text
                      @click="saveRole"
                    >
                      Save
                    </v-btn>
                    <v-btn
                      v-else
                      color="blue darken-1"
                      text
                      @click="updateRole"
                    >
                      Update Role
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-row>
        </v-card-title>
        <mayventory-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="roles"
          item-key="name"
          title="Role"
          url="/role/view/"
        >
        </mayventory-table>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  PERMISSION,
  DELETE_ROLE,
  GET_ROLES,
  UPDATE_ROLE,
  ADD_ROLE,
} from "../../../constants/graphql";
import { handleError } from "../../../constants/error";
import Swal from "sweetalert2";
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Roles",
  },
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      viewDialog: false,
      valid: true,
      roleName: "",
      roleId: null,
      permission: [],
      perm: "",
      userPermission: this.$store.state.authData.userPermissions,
      search: "",
      errorMessages: "",
      selected: [],
      headers: [
        {
          text: "S/N",
          align: "start",
          value: "sno",
          width: "10%",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Action", value: "action" },
      ],
      roles: [],
    };
  },
  computed: {
    permissions() {
      return this.$store.state.permissions.permissions;
    },
    itemsNo() {
      return this.roles.map((d, index) => ({ ...d, sno: index + 1 }));
    },
    addRole() {
      if (this.userPermission.includes("add_roles")) {
        return true;
      }
    },
  },
  created() {
    this.$root.$refs.B = this;
  },
  apollo: {
    roles: {
      query: GET_ROLES,
      update: (result) => result.getRoles,
      error(error) {
        handleError(error);
      },
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.$store.dispatch({
      type: "GET_PERMISSION",
      apolloClient: this.$apollo,
    });
  },
  methods: {
    close() {
      this.dialog = false;
      this.isEditing = false;
    },
    edit(item) {
      this.roleName = item.name;
      this.roleId = item.id;
      this.permission = item.permissions;
      this.isEditing = true;
      this.dialog = true;

      this.$apollo.queries.roles.refetch();
    },
    updateRole() {
      const obj = Object.assign(this.permission);

      this.loading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_ROLE,
          variables: {
            roleId: parseInt(this.roleId),
            name: this.roleName,
            permissionList: obj,
          },
        })
        .then((_) => {
          this.$apollo.queries.roles.refetch();
          Swal.fire({
            text: "Role Updated successfully",
            icon: "success",
          });
        });
      this.close();
    },
    deleteRole(roleId, roleName) {
      this.$swal
        .fire({
          text: `Do you wish to delete ${roleName}?`,
          icon: "question",
          showConfirmButton: true,
          confirmButtonText: "Yes",
          showDenyButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$apollo
              .mutate({
                mutation: DELETE_ROLE,
                variables: {
                  roleId: roleId,
                },
              })
              .then((_) => {
                this.$swal.fire({
                  toast: true,
                  text: `${roleName} Deleted from MayVentory`,
                  icon: "success",
                  timer: 3000,
                  timerProgressBar: true,
                  position: "top-end",
                });
              });

            this.$apollo.queries.roles.refetch();
          }
        });
    },
    saveRole() {
      let validate = this.$refs.form.validate();
      if (validate === true) {
        this.loading = true;
        this.$apollo
          .mutate({
            mutation: ADD_ROLE,
            variables: {
              name: this.roleName,
              permissionList: this.permission,
            },
          })
          .then((result) => {
            Swal.fire({
              text: "Role has been successfully created",
              icon: "success",
            });
            this.dialog = false;
            this.$apollo.queries.roles.refetch();
          });

        this.loading = false;
        this.close();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .table-one {
  thead.v-data-table-header {
    tr {
      &:hover {
        background-color: #f2f3f8;
      }
      th {
        span {
          font-size: 16px;
          color: #304156;
        }
      }
    }
    tr {
      td {
        padding-bottom: 20px;
        padding-top: 20px;
      }
    }
  }
  tbody {
    tr {
      &:hover {
        background-color: #f2f3f8 !important;
      }
    }
  }
}
</style>
